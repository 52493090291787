import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './projectText.css';
import Title from 'components/title';
import Button from 'components/button';

const ProjectText = ({ title, repo, site, story }) => (
  <Container>
    <Title as="h1" size="large">
      {title}
    </Title>
    <a href={site}
      target="_blank"
      rel="noopener noreferrer">
      <Button content="Check out the site" />
    </a>
    <a href={repo}
      target="_blank"
      rel="noopener noreferrer">
      <Button content="Look at the code" />
    </a>
    <br />
    {story}

  </Container >
);

ProjectText.propTypes = {
  story: PropTypes.string,
  repo: PropTypes.string,
  site: PropTypes.string,
  title: PropTypes.string
};

export default ProjectText;
