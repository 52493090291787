import React from 'react';
import PropTypes from 'prop-types';
import { Button1 } from './button.css'

const Button = ({ content }) => (
    <Button1>{content}</Button1>
);

Button.propTypes = {
    content: PropTypes.string,
};


export default Button;



