import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.div`
  //   display: inline-grid;
  flex: 1;
  width 50%
  grid-gap: 4rem;
  padding: 4rem 4rem;
  margin: 2rem 2rem;
  line-height: 1.9rem;
  text-align: justify;
  

  ${MEDIA.TABLET`
    display: block;
    width: 82%;
    margin-left: .5em;
    margin-right: 0;
    padding:4rem 0 4rem 4rem;
  `};

  ${MEDIA.PHONE`
    display: block;
    width: 75%;
    margin-left: .5em;
    margin-right: 1em;
    padding:4rem 2rem 4rem 4rem;
`};
`;
