import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Button1 = styled.button`
    display:inline-block;
    border:0.1em solid #000000;
    border-radius:0.12em;
    box-sizing: border-box;
    color:#FFFFFF;
    background-color:#000000;
    transition: all 0.2s;
    height: 5%;
    padding: 0.5em 6.5em 1.5em 0.5em ;
    width: 46.5%;
    margin: 0 1em 1em 0;
    line-height: 1em;
    white-space: nowrap;
    
    &:hover {
    color:#000000;
    background-color:#FFFFFF;
    cursor: pointer;
    }

    ${MEDIA.TABLET`
        width:100%;
        height: 4%;
        line-height: .05em;
        text-align: center;
        padding: 2em;
    `};

    ${MEDIA.XLPHONE`
        width: 100%;
        height: 4%;
        text-align: inherit;
        line-height: .5em;
        padding: 1em 1em 1em .3em;
        margin: 0 0 1em 0;
        text-align: left;
    `};

    ${MEDIA.PHONE`
        width: 100%;
        height: 4%;
        text-align: center;
        line-height: .5em;
        padding: 1em 1em 1em .3em;
        margin: 0 0 1em 0;
    `};

`;