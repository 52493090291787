import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.div`
  padding: 4rem 1rem;
  margin: 2rem 5rem;
  width: 85%;

  ${MEDIA.TABLET`
    display: block;
    width: 80%;
  `};

  ${MEDIA.PHONE`
    display: block;
    width: 80%;
    margin: 1rem 3rem 0 2.5rem;
`};
`;
